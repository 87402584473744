<template>
  <div class="outer">
    <!-- <div class="hl-header">
      <div class="hl-search">
        <div class="hl-title">酒店管理</div>
      </div>
    </div>-->
    <div class="hl-content">
      <div class="step">
        <div>
          <div :class="{ setpnow: stepNo == 1 }">1</div>
          <span>酒店模块</span>
        </div>
        <div class="stepLine"></div>
        <div>
          <div :class="{ setpnow: stepNo == 2 }">2</div>
          <span>酒店信息</span>
        </div>
        <div class="stepLine"></div>
        <div>
          <div :class="{ setpnow: stepNo == 3 }">3</div>
          <span>小程序信息</span>
        </div>
        <div class="stepLine"></div>

        <div>
          <div :class="{ setpnow: stepNo == 4 }">4</div>
          <span>管理员</span>
        </div>
        <div class="stepLine"></div>

        <div>
          <div :class="{ setpnow: stepNo == 5 }">5</div>
          <span>完成</span>
        </div>
      </div>

      <div class="step-cotent">
        <div class="step-mokuai" v-if="stepNo == 1">
          <div>模块设置</div>
          <div class="modeList">
            <div
              v-for="(item, index) in modePower"
              :key="index"
              class="modeOne"
            >
              <div>{{ item.name }}</div>
              <div>
                <el-switch v-model="item.checked"></el-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="step-one" v-if="stepNo == 2">
          <!-- <div class="hotel-logo">
            <div class="img">
              <img src="@/assets/ic_img.png" alt="" />
            </div>
            <div class="logo">上传酒店logo</div>
          </div>-->
          <div class="biaodan">
            <el-form style="overflow: scroll">
              <el-form-item
                label="* 酒店名称"
                prop="请输入酒店名称"
                style="width: 80%"
              >
                <el-input
                  placeholder="请输入酒店名称"
                  v-model="hotelInfo.name"
                  style="width: 100%"
                  maxlength="100"
                ></el-input>
              </el-form-item>
              <div class="banben">
                <el-form-item
                  label="* 酒店地址"
                  prop="请输入酒店详细地址"
                  style="width: 48%"
                  maxlength="255"
                  v-model="hotelInfo.address"
                >
                  <el-input
                    placeholder="请输入酒店详细地址"
                    v-model="hotelInfo.address"
                    style="width: 100%"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="X坐标,Y坐标"
                  prop="locX"
                  style="width: 48%; position: relative"
                >
                  <el-input
                    placeholder="请输入X坐标"
                    v-model="locXY"
                    style="width: 100%"
                  ></el-input>
                  <a
                    target="_blank"
                    href="https://lbs.qq.com/getPoint/"
                    class="zuobiaodingwei"
                    >点此定位坐标</a
                  >
                </el-form-item>
              </div>

              <div class="banben">
                <el-form-item
                  label="酒店座机号"
                  prop="mobile"
                  style="width: 48%"
                >
                  <el-input
                    placeholder="请输入酒店座机号"
                    v-model="hotelInfo.mobile"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="酒店传真" prop="fax" style="width: 48%">
                  <el-input
                    placeholder="请输入酒店传真"
                    v-model="hotelInfo.fax"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="yingye">
                <div class="riqi">
                  <div class="zhizhao">
                    <el-form-item label="LOGO" prop="logo" style="width: 100%">
                      <div @click="upLoadFile" class="upLoadFile">
                        <input
                          type="file"
                          ref="fileInput"
                          @change="readFile"
                          style="display: none"
                        />
                        <img
                          class="img"
                          :src="hotelInfo.logo"
                          alt
                          v-if="hotelInfo.logo"
                        />
                        <div class="addlogo" v-else>
                          <i class="el-icon-plus avatar-uploader-icon"></i>
                        </div>
                      </div>
                    </el-form-item>
                  </div>
                  <el-form-item
                    label="* 使用有效期"
                    prop="请选择使用有效期必填"
                    style="width: 100%; margin-top: 50px"
                  >
                    <el-date-picker
                      v-model="hotelInfo.validityTime"
                      type="date"
                      placeholder="请选择使用有效期"
                      style="width: 100%"
                      value-format="yyyy-MM-dd"
                      format="yyyy-MM-dd"
                      :picker-options="pickerOptions0"
                    ></el-date-picker>
                    <img src="@/assets/ic_calendar.png" alt class="calendar" />
                  </el-form-item>

                  <el-form-item
                    label="* 状态选择"
                    prop="请选择状态选择"
                    style="margin-top: 20px"
                  >
                    <el-radio-group v-model="hotelInfo.status">
                      <el-radio :label="0">启动</el-radio>
                      <el-radio :label="1">禁用</el-radio>
                    </el-radio-group>
                  </el-form-item>
                </div>
                <div class="zhizhao">
                  <el-form-item
                    label="* 营业执照"
                    prop="license"
                    style="width: 100%"
                  >
                    <div @click="upLoadFile1" class="upLoadFile1">
                      <input
                        type="file"
                        ref="fileInput1"
                        @change="readFile1"
                        style="display: none"
                      />
                      <img
                        class="img"
                        :src="hotelInfo.license"
                        alt
                        v-if="hotelInfo.license"
                      />
                      <div class="add" v-else>
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                      </div>
                    </div>
                  </el-form-item>
                </div>
              </div>
              <div class="yingyeLine"></div>
              <div class="banben" style="margin: 30px 0">
                <el-form-item
                  label="负责人姓名"
                  prop="linkName"
                  style="width: 48%"
                >
                  <el-input
                    placeholder="请输入负责人姓名"
                    v-model="hotelInfo.linkName"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <el-form-item
                  label="负责人电话"
                  prop="linkPhone"
                  style="width: 48%"
                >
                  <el-input
                    placeholder="请输入负责人电话"
                    v-model="hotelInfo.linkPhone"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="yingyeLine"></div>
            </el-form>
          </div>
        </div>

        <!-- 2 -->
        <div class="step-two" v-if="stepNo == 3">
          <div class="hotel-logo"></div>
          <div class="biaodan-two">
            <el-form>
              <div class="banben">
                <el-form-item label="APPID" style="width: 48%">
                  <el-input
                    placeholder="请输入appId"
                    v-model="wechatInfo.appId"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="appSecret" style="width: 48%">
                  <el-input
                    placeholder="请输入appSecret"
                    v-model="wechatInfo.appSecret"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>
              <div class="yingyeLine"></div>
              <div class="banben" style="margin: 30px 0">
                <el-form-item label="商户号MchId" style="width: 48%">
                  <el-input
                    placeholder="请输入商户号MchId"
                    v-model="wechatInfo.mchId"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商户支付密钥" style="width: 48%">
                  <el-input
                    placeholder="请输入商户支付密钥"
                    v-model="wechatInfo.appKey"
                    style="width: 100%"
                  ></el-input>
                </el-form-item>
              </div>
              <el-form-item label="商户退款证书" style="width: 80%">
                <el-input
                  placeholder="证书地址"
                  v-model="wechatInfo.certPath"
                  style="width: 100%"
                  disabled
                ></el-input>
              </el-form-item>
              <el-form-item style="width: 80%">
                <div class="uplound" @click="upLoadFile2">
                  <input
                    type="file"
                    ref="fileInput2"
                    @change="readFile2"
                    style="display: none"
                  />
                  <div class="upwenjian">
                    <img src="@/assets/ic_upload.png" alt />
                    <span></span> 上传证书
                  </div>
                  <span class="tips"
                    >注：文件格式为 apiclient_cert.p12，文件大小不超过1M。</span
                  >
                </div>
              </el-form-item>
              <el-form-item label="小程序版本" style="width: 80%">
                <el-select
                  v-model="wechatInfo.versionId"
                  placeholder="请选择小程序版本"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in versionList"
                    :key="item.id"
                    :label="item.versionName"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <div class="yingyeLine"></div>
            </el-form>
          </div>
        </div>
        <!-- 3 -->
        <div class="step-three" v-if="stepNo == 4">
          <el-form>
            <div class="userinfo">
              <el-form-item
                label="* 租户ID"
                prop="请输入 租户ID"
                style="width: 40%"
              >
                <el-input
                  placeholder="请输入租户ID"
                  v-model="seruser.tenantId"
                  style="width: 100%"
                  maxlength="100"
                ></el-input>
              </el-form-item>

              <el-form-item
                label="* 姓名/工号"
                prop="请输入姓名/工号"
                style="width: 40%"
              >
                <el-input
                  placeholder="请输入姓名/工号"
                  v-model="seruser.searchKey"
                  style="width: 100%"
                  maxlength="100"
                ></el-input>
              </el-form-item>

              <el-button class="seruserbtn" @click="searchUser" type="primary"
                >查询</el-button
              >
            </div>
            <div class="renyuanbot">
              <div class="quanxian">
                <el-tree
                  :data="hotelModeCheck"
                  show-checkbox
                  node-key="id"
                  ref="tree"
                  :props="modeProps"
                ></el-tree>
              </div>
              <div class="renyuan">
                <el-table
                  class="hl-table"
                  style="width: 100%"
                  :data="userList"
                  :header-cell-style="{
                    background: '#F4F5F9',
                    color: '#000000',
                  }"
                >
                  <el-table-column label width="50px">
                    <template slot-scope="scope">
                      <el-checkbox
                        v-model="scope.row.checked"
                        @change="changeuser(scope.$index)"
                      ></el-checkbox>
                    </template>
                  </el-table-column>
                  <el-table-column label="头像" width="50px">
                    <template slot-scope="scope">
                      <img
                        class="touxiang"
                        v-if="scope.row.avatar != ''"
                        :src="scope.row.avatar"
                      />
                      <!-- <img class="touxiang" v-else src="../../assets/logo.png" /> -->
                    </template>
                  </el-table-column>
                  <el-table-column label="ID">
                    <template slot-scope="scope">
                      <span>{{ scope.row.id }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="工号">
                    <template slot-scope="scope">
                      <span>{{ scope.row.empId }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="昵称">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nickNameCn }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="姓名">
                    <template slot-scope="scope">
                      <span>{{ scope.row.nameCn }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="状态">
                    <template slot-scope="scope">
                      <div class="zt">
                        <div v-if="scope.row.hrStatus == 'A'">
                          <span class="qiyong"></span>
                          <span>在职</span>
                        </div>
                        <div v-else>
                          <span class="jinyong"></span>
                          <span>离职</span>
                        </div>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </el-form>
        </div>
        <div class="step-four" v-if="stepNo == 5">
          <div class="successful">
            <img v-if="isregiest" src="@/assets/ic_check.png" alt />
            <div v-if="!isregiest" class="hotel-success">{{ registmsg }}</div>
            <div v-if="isregiest" class="hotel-success">
              {{ hotelInfo.name }} 创建成功
            </div>
            <div v-if="isregiest" class="other-cz">现在可以进行其他操作</div>
            <el-button
              size="small"
              type="primary"
              @click="backhotel()"
              class="backHotel"
              >返回酒店列表</el-button
            >
          </div>
        </div>
      </div>

      <div class="sure">
        <div
          @click="up()"
          v-if="!isregiest"
          :class="stepNo == 1 ? 'cando' : ''"
        >
          上一步
        </div>
        <div
          @click="down()"
          v-if="!isregiest"
          :class="stepNo != 5 ? '' : 'cando'"
        >
          {{ nextName }}
        </div>
      </div>
    </div>

    <addModify ref="addModify" />
  </div>
</template>
<script>
postRequest;
import { getRequest, postRequest } from "@/api/api.js";
import axios from "axios";
import global from "../../Global.vue";
// import addModify from "@/views/jiudianguanli/addModify.vue";
export default {
  data() {
    return {
      pickerOptions0: {
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      },
      registmsg: "注册中,请勿关闭此页面...",
      nextName: "下一步",
      isregiest: false,
      locXY: null,
      stepNo: 1, //步骤
      hotelInfo: {
        status: 0,
        license: null,
        logo: null,
      }, //酒店信息
      hotelModeCheck: [], //酒店拥有模块
      hotelModeHave: [], //酒店拥有模块
      hotelUser: null, //管理员
      hotelUserPower: [], //管理员权限
      wechatInfo: {
        certPath: null,
      }, //小程序配置信息
      modePower: [], //模块权限
      versionList: [], //小程序版本
      seruser: {
        tenantId: "10008554561",
        searchKey: "吕",
      }, //墨子账号
      userList: [], //墨子人员

      modeProps: {
        children: "list",
        label: "name",
      },
      //表单验证
    };
  },
  components: {
    addModify,
  },
  created() {
    this.selectHotelModeHotelPower(); //获取模块及权限

    // this.searchUser();//获取墨子人员  //需要删除
  },
  methods: {
    backhotel() {
      this.$router.push("/jiudianguanli");
    },
    hotelInsert() {
      let loadingInstance = this.$loading({ text: "正在注册酒店..." }); //如果这数据不存在，则要去接口请求数据
      this.hotelUser.code = this.hotelUser.id;
      console.log("hotelInfo", this.hotelInfo);
      console.log("hotelModeHave", this.hotelModeHave);
      console.log("hotelUser", this.hotelUser);
      console.log("hotelUserPower", this.hotelUserPower);
      console.log("wechatInfo", this.wechatInfo);
      var zcDate = {
        hotelInfo: this.hotelInfo,
        hotelModeHave: this.hotelModeHave,
        hotelUser: this.hotelUser,
        hotelUserPower: this.hotelUserPower,
        wechatInfo: this.wechatInfo,
      };
      console.log("111", zcDate);
      postRequest("hotelInsert", zcDate).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.isregiest = true;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },

    changeuser(chooseindex) {
      this.hotelUser = null;
      this.userList.forEach((item, index) => {
        if (chooseindex == index) {
          item.checked = true;
          this.hotelUser = item;
        } else {
          item.checked = false;
        }
      });
    },
    searchUser() {
      let loadingInstance = this.$loading({ text: "正在查询用户..." }); //如果这数据不存在，则要去接口请求数据
      getRequest(
        "searchUser?tenantId=" +
          this.seruser.tenantId +
          "&searchKey=" +
          this.seruser.searchKey
      ).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          res.data.forEach((item, index) => {
            item.checked = false;
          });
          this.userList = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    down() {
      if (this.stepNo == 1) {
        this.hotelModeCheck = [];
        this.modePower.forEach((item, index) => {
          if (item.checked) {
            this.hotelModeCheck.push(item);
          }
        });
        if (this.hotelModeCheck.length <= 0) {
          this.$message({
            duration: 5000, message: "请选择酒店使用模块！", type: "warning" });
          return;
        }
        this.stepNo++;
      } else if (this.stepNo == 2) {
        if (this.hotelInfo.name == null || this.hotelInfo.name == "") {
          this.$message({
            duration: 5000, message: "请输入酒店名称", type: "warning" });
          return;
        }
        if (this.hotelInfo.address == null || this.hotelInfo.address == "") {
          this.$message({
            duration: 5000, message: "请输入酒店地址", type: "warning" });
          return;
        }
        if (this.hotelInfo.license == null || this.hotelInfo.license == "") {
          this.$message({
            duration: 5000, message: "请上传营业执照", type: "warning" });
          return;
        }
        if (
          this.hotelInfo.validityTime == null ||
          this.hotelInfo.validityTime == ""
        ) {
          this.$message({
            duration: 5000, message: "请选择使用有效期", type: "warning" });
          return;
        }

        if (this.locXY != null && this.locXY != "") {
          var xy = this.locXY.split(",");
          this.hotelInfo.locX = xy[0];
          this.hotelInfo.locY = xy[1];
          if (this.hotelInfo.locX == null || this.hotelInfo.locX == "") {
            this.$message({
            duration: 5000, message: "坐标不正确", type: "warning" });
            return;
          }
          if (this.hotelInfo.locY == null || this.hotelInfo.locY == "") {
            this.$message({
            duration: 5000, message: "坐标不正确", type: "warning" });
            return;
          }
        }
        this.stepNo++;
        this.selectSysWechatVersion(); //获取小程序版本
      } else if (this.stepNo == 3) {
        if (this.wechatInfo.appId == null || this.wechatInfo.appId == "") {
          this.$message({
            duration: 5000, message: "请输入appId", type: "warning" });
          return;
        }
        if (
          this.wechatInfo.appSecret == null ||
          this.wechatInfo.appSecret == ""
        ) {
          this.$message({
            duration: 5000, message: "请输入appSecret", type: "warning" });
          return;
        }
        if (this.wechatInfo.mchId == null || this.wechatInfo.mchId == "") {
          this.$message({
            duration: 5000, message: "请输入商户号MchId", type: "warning" });
          return;
        }
        if (this.wechatInfo.appKey == null || this.wechatInfo.appKey == "") {
          this.$message({
            duration: 5000, message: "商户支付密钥", type: "warning" });
          return;
        }
        if (
          this.wechatInfo.certPath == null ||
          this.wechatInfo.certPath == ""
        ) {
          this.$message({
            duration: 5000, message: "请上传商户退款证书", type: "warning" });
          return;
        }
        if (
          this.wechatInfo.versionId == null ||
          this.wechatInfo.versionId == ""
        ) {
          this.$message({
            duration: 5000, message: "请选择小程序版本", type: "warning" });
          return;
        }
        this.stepNo++;
        this.nextName = "确认无误";
      } else if (this.stepNo == 4) {
        this.hotelModeHave = [];
        this.hotelUserPower = [];
        var checkquanxian = this.$refs.tree.getCheckedNodes();
        checkquanxian.forEach((item, index) => {
          if (item.code.length == "3") {
            //模块
            this.hotelModeHave.push(item);
          } else if (item.code.length == "5") {
            //模块
            this.hotelUserPower.push(item);
          }
        });
        if (this.hotelModeHave.length <= 0) {
          this.$message({
            duration: 5000,
            message: "请分配权限",
            center: true,
          });
          return;
        }
        if (this.hotelUserPower.length <= 0) {
          this.$message({
            duration: 5000,
            message: "请分配权限",
            center: true,
          });
          return;
        }
        if (this.hotelUser == null || this.hotelUser.id <= 0) {
          this.$message({
            duration: 5000,
            message: "请选择人员",
            center: true,
          });
          return;
        }

        this.stepNo++;
        this.hotelInsert();
      }
    },
    up() {
      if (this.stepNo > 1) {
        this.stepNo--;
      }
    },
    // 注册酒店
    regHotel() {},

    upLoadFile() {
      this.$refs.fileInput.click();
    },
    readFile(e) {
      let that = this;
      let file = e.target.files[0];
      if (file == null) {
        return;
      }
      let name = "." + file.name.split(".")[1];
      if (!name.match(/.png|.gif|.jpeg|.jpg|.jfif/i)) {
        this.$message({
            duration: 5000,
          message: "请选择图片格式!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      if (file.size / 1024 > 1024) {
        this.$message({
            duration: 5000,
          message: "请选择1M以内的图片!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      let param = new FormData(); // 创建form对象
      param.append("file", file); // 通过append向form对象添加数据
      // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: window.sessionStorage.getItem("token"),
        },
      };
      // 添加请求头
      axios.post(global.upload, param, config).then((res) => {
        if (res.data.status == 200) {
          that.hotelInfo.logo = global.imgurl + res.data.data;
        }
      });
    },

    upLoadFile1() {
      this.$refs.fileInput1.click();
    },
    readFile1(e) {
      let that = this;
      let file = e.target.files[0];
      if (file == null) {
        return;
      }
      let name = "." + file.name.split(".")[1];
      if (!name.match(/.png|.gif|.jpeg|.jpg|.jfif/i)) {
        this.$message({
            duration: 5000,
          message: "请选择图片格式!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      if (file.size / 1024 > 1024) {
        this.$message({
            duration: 5000,
          message: "请选择1M以内的图片!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      let param = new FormData(); // 创建form对象
      param.append("file", file); // 通过append向form对象添加数据
      // FormData私有类对象，访问不到，可以通过get判断值是否传进去
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          token: window.sessionStorage.getItem("token"),
        },
      };
      // 添加请求头
      axios.post(global.upload, param, config).then((res) => {
        if (res.data.status == 200) {
          that.hotelInfo.license = global.imgurl + res.data.data;
        }
      });
    },

    upLoadFile2() {
      this.$refs.fileInput2.click();
    },
    readFile2(e) {
      let that = this;
      // 获取到当前文件对象
      const file = e.target.files[0];

      let name = "." + file.name.split(".")[1];
      if (name != ".p12") {
        this.$message({
            duration: 5000,
          message: "证书格式不正确!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }

      // 传递一个FormData对象即可
      const formData = new FormData();
      formData.append("file", file); // 'file'可变相当于input表单的name属性
      if (file.size / 1024 > 1024) {
        this.$message({
            duration: 5000,
          message: "请选择1M以内的文件!",
          type: "error",
          time: 2000,
          hasClose: true,
        });
        return;
      }
      // 服务器只需按照正常的上传程序代码即可
      axios
        .post(global.upload, formData, {
          // xsrfHeaderName: 'Authorization', // 请求头的名字自定义，用你们后台给你的就是
          headers: {
            "Content-Type": "multipart/form-data",
            token: window.sessionStorage.getItem("token"),
          },
        })
        .then((res) => {
          if (res.data.status == "200") {
            this.$message({
            duration: 5000,
              message: "上传成功",
              center: true,
            });

            that.wechatInfo.certPath = res.data.data;
          } else {
            this.$message({
            duration: 5000,
              message: "上传失败",
              center: true,
            });
          }
        })
        .catch((err) => {
          this.$message({
            duration: 5000,
            message: "服务器出问题啦!!!",
            center: true,
          });
        });
    },

    selectSysWechatVersion() {
      let loadingInstance = this.$loading({ text: "获取小程序版本..." }); //如果这数据不存在，则要去接口请求数据
      let postData = {
        offset: 1,
        num: 1000,
      };
      postRequest("selectSysWechatVersion", postData).then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          this.versionList = res.data.data.filter((item) => item.status == 0);
          // this.versionList = res.data.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
    selectHotelModeHotelPower() {
      let loadingInstance = this.$loading({ text: "模块加载中..." }); //如果这数据不存在，则要去接口请求数据
      postRequest("selectHotelModeHotelPower").then((res) => {
        this.$nextTick(() => {
          loadingInstance.close();
        });
        if (res.status == 200) {
          res.data.forEach((item, index) => {
            item.checked = false;
            res.data[index].list.forEach((item1) => {
              item1.checked = false;
            });
          });
          this.modePower = res.data;
          //需要删除
          this.hotelModeCheck = res.data;
        } else {
          this.$message({
            duration: 5000,
            message: res.message,
            center: true,
          });
        }
      });
    },
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.outer {
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
}
.button {
  width: 60px;
  height: 30px;
  /* background:rgb(163, 197, 248) ; */
  background-color: cornflowerblue;
  color: white;
  border: none;
}
.button2 {
  background-color: white;
  border: 1px solid #999;
  color: #666;
}
.hl-header {
  width: 100%;
  height: 60px;
  display: flex;
}
.hl-search {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  position: relative;
}
.hl-title {
  font-size: 20px;
  font-weight: bold;
  line-height: 30px;
  color: rgba(0, 0, 0, 0.85);
}
.addIdType {
  width: 136px;
  height: 40px;
  background: #387dff;
  border-radius: 4px;
}
.hl-search .span {
  width: 40px;
  height: 30px;
  font-size: 14px;
  position: absolute;
  right: 10px;
  top: 5px;
  font-size: 14px;
  border: none;
}
.hl-content {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.mokuai {
  font-size: 16px;
  font-weight: 500;
  color: #000000;
  opacity: 0.85;
  margin-bottom: 21px;
}
.hl-table {
  /* background: red; */
  overflow: auto;
}
.cz-button {
  width: 74px;
  height: 28px;
  background: #fe7c4b;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
}
.qidong {
  width: 90px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 24px;
}
.line {
  display: inline-block;
  height: 14px;
  border: 1px solid #e8ecef;
}
.cz {
  display: flex;
}
.block {
  position: absolute;
  bottom: 2px;
  right: 32px;
}
.qiyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #387dff;
  border-radius: 50%;
  margin-right: 6px;
}
.jinyong {
  display: inline-block;
  width: 6px;
  height: 6px;
  background: #f5222d;
  border-radius: 50%;
  margin-right: 6px;
}
.zt > div {
  display: flex;
  align-items: center;
}
.search {
  display: flex;
}
.search-box {
  margin-right: 23px;
  width: 368px;
}
.step {
  height: 90px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  opacity: 1;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 72px;
}
.step > div {
  display: flex;
  align-items: center;
}
.step > div > div:first-child {
  width: 32px;
  text-align: center;
  height: 32px;
  line-height: 30px;
  border: 1px solid #ccc;
  color: #fff;
  border-radius: 50%;
  opacity: 1;
  margin-right: 8px;
  color: #000000;
  font-weight: bold;
}
.step .setpnow {
  border: 1px solid #387dff !important;
  background: #387dff !important;
  color: #fff !important;
}
.stepLine {
  width: 176px;
  height: 1px;
  background: #e7e8e9;
  opacity: 1;
}
.step-cotent {
  margin-top: 24px;
  background: #ffffff;
  border: 1px solid #e8ecef;
  opacity: 1;
  border-radius: 4px;
  height: 78%;
}
.step-one {
  margin: 41px 0 0 92px;
  display: flex;
  height: 78%;
  overflow: scroll;
}
.step-two {
  margin: 41px 0 0 92px;
  display: flex;
  height: 80%;
  overflow: scroll;
}
.step-four {
  display: flex;
  height: 80%;
}
.step-one .img {
  width: 100%;
  height: 100%;
  background: #f4f5f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.step-one .img1 {
  width: 100%;
  height: 100%;
  background: #f4f5f9;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}
.logo {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  color: #4379ee;
  margin-top: 12px;
}
.hotel-logo {
  flex: 1;
}
.biaodan {
  flex: 6;
}
.biaodan-two {
  flex: 9;
}
.yingyeLine {
  width: 100%;
  height: 1px;
  background: #e8ecef;
  opacity: 1;
  border-radius: 0px;
  margin-top: 35px;
  margin-left: -75px;
}
.banben {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.yingye {
  display: flex;
  justify-content: space-between;
  width: 80%;
}
.el-form-item__label {
  display: block !important;
  width: 100% !important;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  opacity: 0.65;
}
.el-radio {
  margin-right: 30px;
}
.riqi {
  width: 48%;
}
.zhizhao {
  width: 48%;
}
.upLoadFile {
  width: 100%;
  height: 150px;
  /* background: #1471fe; */
}
.upLoadFile1 {
  width: 100%;
  height: 350px;
  /* background: #1471fe; */
}
.addlogo {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 150px;
  background: #fefeff;
  border: 3px dashed #e8ecef;
  opacity: 1;
  border-radius: 4px;
  color: #e8ecef;
}
.add {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 350px;
  background: #fefeff;
  border: 3px dashed #e8ecef;
  opacity: 1;
  border-radius: 4px;
  color: #e8ecef;
}
.el-icon-plus {
  font-size: 40px;
  font-weight: bold;
}
.sure {
  height: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  bottom: 0;
  width: 80%;
}
.sure > div {
  width: 256px;
  height: 48px;
  opacity: 0.9;
  border-radius: 4px;
  color: #fff;
  background: #387dff;
  line-height: 48px;
  font-size: 18px;
  margin-right: 25px;
  cursor: pointer;
}
.sure > div:hover {
  transform: scale(1.1);
}
.cando {
  background: #d8d8d8 !important;
}
.upwenjian {
  width: 108px;
  height: 40px;
  background: rgba(56, 125, 255, 0.13);
  border: 1px solid #387dff;
  opacity: 1;
  color: #387dff;
  text-align: center;
  border-radius: 4px;
  margin: 20px 0;
}
.uplound {
  display: flex;
  position: relative;
}
.tips {
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  color: #ff5f58;
  position: absolute;
  bottom: 20px;
  left: 120px;
}
.successful {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.hotel-success {
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  color: rgba(0, 0, 0, 0.85);
  margin-top: 16px;
}
.other-cz {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 8px;
}
.backHotel {
  width: 116px;
  height: 32px;
  background: #387dff;
  border-radius: 4px;
  margin-top: 26px;
}

.calendar {
  position: absolute;
  right: 10px;
  top: 47px;
}
.el-input__prefix {
  display: none;
}
.el-input--prefix .el-input__inner {
  padding: 0 15px;
}

.step-mokuai {
  padding: 24px 32px;
}
.modeList {
  padding: 30px;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
}
.modeOne {
  height: 70px;
  line-height: 70px;
  width: 28%;
  border: 1pxsolid red;
  display: flex;
  justify-content: space-between;
  padding: 0px20px;
  margin: 0% 2%;
}

.zuobiaodingwei {
  position: absolute;
  right: 0;
  top: 0;
  cursor: pointer;
  text-decoration: underline;
}
.zuobiaodingwei:hover {
  transform: scale(1.1);
}
.step-three {
  padding: 25px 5%;
  overflow: hidden;
  height: 100%;
}

.userinfo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 120px;
}
.seruserbtn {
  width: 113px;
  height: 40px;
  margin-top: 40px;
}
.seruserbtn:hover {
  transform: scale(1.1);
}
.renyuanbot {
  display: flex;
}
.quanxian {
  width: 20%;
  border: 1px solid #e8ecef;

  min-height: 450px;
  max-height: 500px;
  overflow: auto;
}
.renyuan {
  width: 79%;
  margin-left: 1%;
  border: 1px solid #e8ecef;
  max-height: 500px;
  overflow: auto;
}
.touxiang {
  width: 20px;
  height: 20px;
}
</style>